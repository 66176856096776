function init_cpx_select() {
    $('#vue-menukaart').on("click", ".cpx-select-box select" , function() {
        $(this).parent(".cpx-select-box").toggleClass("open");
    });

    $(document).mouseup(function (e) {
        var container = $(".cpx-select-box");

        if (container.has(e.target).length === 0) {
            container.removeClass("open");
        }
    });

    // $('#vue-menukaart').on("change", '.cpx-select-box select' , function() {
    // var selection = $(this).find("option:selected").text(),
    //     labelFor = $(this).attr("id"),
    //     label = $("[for='" + labelFor + "']");
    //     label.find(".label-desc").html(selection);
    // });
}

$(document).ready(function() {
    init_cpx_select();
});