function init_banner() {
    var $swiper_wrapper = $('.swiper-wrapper');

    if($swiper_wrapper && $swiper_wrapper.children('.swiper-slide').length > 1) {
      const swiper = new Swiper('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        autoHeight: true,
        watchOverflow: true,
        grabCursor: true,

        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },
      });
    }
}

$(document).ready(function() {
    init_banner();
});